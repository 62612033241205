import { CloseOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import { ReactComponent as BurgerIcon } from "../../assets/burgerButtonLogo.svg";
import { useScrollDirection } from "../../hooks/useScrollDirection";
import {
  Container,
  NavItem,
  Button,
  LogoSection,
  BurgerButtonBlock,
  BurgerButton,
  LogoOriginal,
  NavBarSection,
  NavBar,
  ButtonWrapper,
} from "./styled/headerComponents";
import { LinkComponent } from "../main/styled/mainComponents";
import React from "react";

interface IProps {
  isOpened: boolean
  onOpen: () => void
  handleModal: () => void;
}

export const Header = ({ isOpened, onOpen, handleModal }: IProps) => {
  const scrollDirection = useScrollDirection();
  return (
    <Container headerActive={scrollDirection !== "down"}>
      <LogoSection>
        <BurgerButtonBlock>
          <BurgerButton onClick={onOpen}>
            {isOpened ? <CloseOutlined /> : <Icon component={BurgerIcon} />}
          </BurgerButton>
        </BurgerButtonBlock>
        <LogoOriginal>
          <LinkComponent to={"/"}>R.A HONEST PARTNER</LinkComponent>
        </LogoOriginal>
      </LogoSection>
      <NavBarSection>
        <NavBar>
          <NavItem href={"/#about"}>Uslugi</NavItem>
          <NavItem href={"/#proces"}>Proces</NavItem>
          <LinkComponent to="/gallery">
            <NavItem>Projekty</NavItem>
          </LinkComponent>
          <NavItem href={"/#contacts"}>Kontakt</NavItem>
        </NavBar>
        <ButtonWrapper>
          <Button onClick={handleModal}>Wyślij zapytanie</Button>
        </ButtonWrapper>
      </NavBarSection>
    </Container>
  );
};
